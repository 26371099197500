<template>
    <div class="world-spire">
      <div class="persons">
        <img src="../../assets/cdp/ads.png" alt="">
      </div>
      <div class="info">
        <h1>{{t("product.worldspire.title")}}</h1>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 0}" @click="handleClick(0)">{{t("product.worldspire.sub_title1")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 0}">{{t("product.worldspire.sub_dec1")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 1}" @click="handleClick(1)">{{t("product.worldspire.sub_title2")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 1}">{{t("product.worldspire.sub_dec2")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 2}" @click="handleClick(2)">{{t("product.worldspire.sub_title3")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 2}">{{t("product.worldspire.sub_dec3")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 3}" @click="handleClick(3)">{{t("product.worldspire.sub_title4")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 3}">{{t("product.worldspire.sub_dec4")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 4}" @click="handleClick(4)">{{t("product.worldspire.sub_title5")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 4}">{{t("product.worldspire.sub_dec5")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 5}" @click="handleClick(5)">{{t("product.worldspire.sub_title6")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 5}">{{t("product.worldspire.sub_dec6")}}</div>
        </div>
        <div class="line-box">
          <div class="line-title" :class="{active: isActive === 6}" @click="handleClick(6)">{{t("product.worldspire.sub_title7")}} <img src="../../assets/cdp/down.png"></div>
          <div class="line-message" :class="{active: isActive === 6}">{{t("product.worldspire.sub_dec7")}}</div>
        </div>
      </div>
    </div>
</template>
<script>
import { useI18n } from "vue-i18n";
export default {
  name: "Explain",
  data() {
    return {
    isActive: null
    }
},
  setup() {
    const { t } = useI18n();
    return {
      t
    };
  },
  methods: {
    handleClick(e) {
    this.isActive = this.isActive === e ? null : e
    }
},
};
</script>
<style lang="less">
.world-spire {
    max-width: 1300px;
    margin: 80px auto;
    display: flex;
    align-items: center;
    user-select: none;
    .persons {
      flex: 1;
      margin-right: 20px;
      img {
        width: 90%;
      }
    }
    .info {
      flex: 1;
      h1 {
        margin: 0;
        line-height: 100px;
        border-bottom: 1px solid #ccc;
      }
      .line-box {
        border-bottom: 1px solid #ccc;
        .line-title {
          line-height: 60px;
          font-size: 20px;
          font-weight: 600;
          color: #333333;
          cursor: pointer;
          position: relative;
          img {
            width: 12px;
            position: absolute;
            top: 48%;
            right: 10px;
            transition: transform 0.5s ease-in-out;
          }
          &.active {
            color: #ff9333;
            img {
              transform:rotate(180deg);
              transition: transform 0.5s ease-in-out;
            }
          }
        }
        .line-message {
          // display: none;
          height: 0;
          overflow: hidden;
          // margin-bottom: 20px;
          transition: height 0.5s ease-in-out;
          font-size: 12px;
          &.active {
            // display: block;
            height: 80px;
            transition: height 0.5s ease-in-out;
          }
        }
      }
    }
}
</style>
